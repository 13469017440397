import React from "react";
import './Blog.scss';
import Arrow2 from '../../Images/next.png'
import Review from '../../Images/Review.png'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// images
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"


export default function Blog({ FaqList, Blogs }) {
    const location = useLocation()
    const navigate = useNavigate()
    const [getPathValue, setgetPathValue] = useState("");

    const [header, setHeader] = useState(false)
    const listenScrollEvent = () => {
        if (window.scrollY < 50 && window.screen.width < 690) {
            return setHeader(false)
        } else if (window.scrollY > 50 && window.screen.width < 690) {
            return setHeader(true)
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () =>
            window.removeEventListener('scroll', listenScrollEvent);

    }, [window.scrollY]);

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }
    // readingTime();

    useEffect(() => {

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        let value = params.category;
        setgetPathValue(params.category ? params.category : null)
        console.log(value);
        console.log(getPathValue);
    }, []);


    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());

    return (

        <div className="Blog">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Temecula, CA Heating & Air Conditioning | Blog And Magazine</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Temecula, CA Heating & Air Conditioning | Blog And Magazine" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Temecula, CA Heating & Air Conditioning | Blog And Magazine" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* Blog Tags */}
                <meta name="PageType" content="article" />
                <meta name="ContentType" content="article" />
                <meta name="Category" content="article" />
                <meta name="SubCategories" content="article" />
                <meta name="FunnelStage" content="upper" />
                <meta name="Objective" content="education, seo" />




                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.temeculahvacairconditioning.org/blog" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the Temecula, CA Heating & Air Conditioning Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to Heating & Air Conditioning services, secure your home." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the Temecula, CA Heating & Air Conditioning Faq Page for insightful articles on strategies, tips, and expert advice. Discover innovative ways to Heating & Air Conditioning services, secure your home." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Temecula, CA Heating & Air Conditioning | Blog And Magazine" data-react-helmet="true" />
                <meta name="keywords" content="HVAC services Temecula CA, Air conditioning repair Temecula, Heating repair Temecula CA, HVAC installation Temecula, Furnace repair Temecula, AC maintenance Temecula CA, Air duct cleaning Temecula, Emergency HVAC services Temecula, HVAC contractors Temecula CA, Residential HVAC Temecula, Commercial HVAC Temecula, HVAC tune-up Temecula, AC replacement Temecula, Heating system installation Temecula, Indoor air quality Temecula CA, HVAC repair near me Temecula, Central air conditioning Temecula, Ductless mini-split Temecula, HVAC service plan Temecula, Thermostat installation Temecula, 24/7 HVAC service Temecula, Energy-efficient HVAC Temecula, Air filter replacement Temecula, Heat pump repair Temecula, HVAC maintenance Temecula CA, HVAC inspection Temecula, Air conditioner installation Temecula, Heating and cooling Temecula, AC service Temecula CA, HVAC system replacement Temecula, Furnace installation Temecula CA, HVAC repair cost Temecula, Local HVAC company Temecula, Affordable HVAC Temecula, AC troubleshooting Temecula, HVAC technicians Temecula CA, HVAC emergency repair Temecula, Heater repair Temecula CA, Air conditioning services Temecula, Commercial air conditioning Temecula, Furnace tune-up Temecula, Ductwork repair Temecula CA, HVAC financing Temecula, HVAC deals Temecula CA, Heating and air conditioning Temecula, HVAC consultation Temecula, Central heating repair Temecula, HVAC contractor near me Temecula, Reliable HVAC Temecula CA, Licensed HVAC contractor Temecula" data-react-helmet="true" />
            </Helmet>
            <div className="BlogHeader">
                <div className="BlogHeaderTop">
                    <h1>Activity & Updates</h1>
                    <h3>
                        Living in Temecula, CA, means enjoying beautiful weather and cozy indoor comfort thanks to your HVAC system. However, to keep your HVAC system safe and efficient, regular maintenance is essential. Here’s why maintaining your HVAC system should be a top priority for every homeowner in Temecula.
                    </h3>
                </div>
                <div className="BlogHeaderBottom">
                    <h1>Articles</h1>
                </div>
            </div>
            <div className="BlogsList">
                {Blogs.filter(a => getPathValue ? a.CategoryPath == getPathValue : a.CategoryPath != getPathValue).reverse().map((A, index) =>
                    <a href={`/blog/${A.BlogPath.replace(/ /g, "_").toLowerCase() + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListBox" >
                        <article>
                            <div className="BlogsListBoxLeft">
                                <img src={A.Photos} alt={`${A.BlogPath} | Temecula Heating & Air Conditioning | SENEX | Business | Busniess Laon | ondeck`} />
                            </div>
                            <div className="BlogsListBoxRight">
                                <h1>{A.Title}</h1>
                                <h2>{A.Discription.slice(0, 60 - 1) + "..."}</h2>
                                <span>
                                    <h5>{readingTime(Blogs[index])} MIN READ</h5>
                                    <h4>{A.Date}</h4>
                                </span>
                            </div>
                        </article>

                    </a>
                )}
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogApply">
                <h1>Elevate Your Business with Small Business Lending Specialists</h1>
                <div className="BlogApplyBox">
                    <img src={Review} alt="business loan reviews, small business reviews, business line of credit reviews, small business lenders" />
                    <h2> Submit your application within minutes <br /> No commitment required</h2>
                    <button onClick={() => navigate('/apply')}>Apply Now</button>
                </div>

            </div>

            <NavigatorPath />

        </div >
    )
}

